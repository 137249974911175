export default function() {
  return {
    values: ['/database'],
    littleHeader: ['/database/shops', '/database/articles'],
    getPath() {
      return this.values
    },
    getLittleHeader() {
      return this.littleHeader
    }
  }
}
