// import colors from 'vuetify/lib/util/colors'
import i18n from '../../i18n'

export default function () {
  return {
    values: [
      {
        title: i18n.t('home.generalData'),
        icon: 'mdi-database',
        description: i18n.t('home.managementShops'),
        // color: colors.grey.darken1,
        color: '#1D4ED8',
        to: 'database',
      },
      {
        title: 'Booker',
        icon: 'mdi-clock-outline',
        description: i18n.t('home.onlineAppointmentBooking'),
        // color: colors.green.darken1,
        color: '#B45309',
        to: 'booker',
        acl: [],
      },
      {
        title: 'Eshop',
        icon: 'mdi-store',
        description: i18n.t('home.onlineProductSale'),
        // color: colors.brown.darken1,
        color: '#BE185D',
        to: 'eshop',
        commingSoon: true,
        inDevelopment: false,
        acl: [],
      },
      // {
      //   title: 'PassBeaute',
      //   icon: 'mdi-pencil',
      //   description: i18n.t('home.dataSheet'),
      //   // color: colors.yellow.darken1,
      //   color: '#047857',
      //   to: 'passbeaute',
      //   commingSoon: true,
      //   inDevelopment: false,
      //   acl: []
      // },
      {
        title: 'Gestion',
        icon: 'mdi-table',
        description: i18n.t('home.gestion'),
        // color: colors.blue.darken1,
        color: '#B91C1C',
        to: 'management',
        commingSoon: false,
        inDevelopment: true,
        secret: false,
        acl: [],
      },
      {
        title: 'Stat',
        icon: 'mdi-table',
        description: i18n.t('home.statistics'),
        // color: colors.blue.darken1,
        color: '#B91C1C',
        to: 'stats',
        commingSoon: true,
        inDevelopment: false,
      },
      // {
      //   title: i18n.t('home.chest'),
      //   icon: 'mdi-sawtooth-wave',
      //   description: i18n.t('home.chest'),
      //   // color: colors.red.darken1,
      //   color: '#374151',
      //   to: 'vault',
      //   commingSoon: true,
      //   inDevelopment: false,
      //   acl: []
      // },
      {
        title: i18n.t('home.communications'),
        icon: '',
        description: i18n.t('home.communications'),
        color: '#DFA500',
        to: 'communications',
        commingSoon: false,
        inDevelopment: false,
        // acl: []
      },
      {
        title: 'Fermetures exceptionnelles',
        icon: 'mdi-pencil',
        description: 'Gestion des jours de fermeture exceptionnelles.',
        // color: colors.yellow.darken1,
        color: '#047857',
        to: 'closeddays',
        commingSoon: false,
        inDevelopment: false,
        acl: [],
      },
      {
        title: 'Coupons de réduction',
        icon: 'mdi-pencil',
        description:
          'Gestion des réductions automatiques et des coupons de réduction.',
        // color: colors.yellow.darken1,
        color: '#047857',
        to: 'coupons',
        commingSoon: false,
        inDevelopment: false,
        acl: [],
      },
    ],

    getBlocks() {
      return this.values
    },
  }
}
