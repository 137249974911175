<template>
  <div v-if="displayAll" class="content">
    <div
      class="back-filter"
      :style="{ width: widthFilter }"
      @click="$emit('close')"
    />
    <div class="content-menu" :style="{ maxWidth: widthMenu }">
      <div class="close" v-ripple @click="$emit('close')">
        <v-icon color="rgb(0,0,0)" large>mdi-close</v-icon>
      </div>
      <div class="menu" :style="{ opacity: contentOpacity }">
        <router-link
          v-for="(block, index) in blocks"
          :key="'menu-' + index"
          :to="block.to"
          :style="{ '--link-color': block.color }"
          class="link"
          v-ripple
        >
          <v-icon color="rgb(0,0,0)">{{ block.icon }}</v-icon>
          <span>{{ block.title }}</span>
        </router-link>
      </div>
      <div class="content-icons" :style="{ opacity: contentOpacity }">
        <div
          v-for="(icon, index) in $vuetify.breakpoint.width < 600
            ? icons.filter(i => !i.responsive)
            : []"
          :key="'icon-' + index"
          class="content-icon"
          v-ripple
          @click="$emit('icon-click', icon.action, icon.to)"
        >
          <img
            class="icon"
            :src="icon.img"
            :title="icon.title"
            :alt="icon.title"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blocks from '@/views/home/blocks.js'
import iconsHead from '@/helpers/iconsHeader'

export default {
  name: 'header-app-menu',
  props: {
    display: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route() {
      if (this.display) this.$emit('close')
    },
    display(value) {
      if (value) {
        this.displayAll = true
        setTimeout(() => {
          this.widthFilter = '100%'
          this.widthMenu = '900px'
        }, 10)
        setTimeout(() => {
          this.contentOpacity = 1
        }, 400)
      } else {
        this.widthFilter = 0
        this.widthMenu = 0
        this.contentOpacity = 0
        setTimeout(() => {
          this.displayAll = false
        }, 510)
      }
    }
  },
  data: () => {
    return {
      icons: iconsHead().getIcons(),
      // blocks: blocks().getBlocks(),
      widthFilter: 0,
      widthMenu: 0,
      displayAll: false,
      contentOpacity: 0
    }
  },
  computed: {
    blocks() {
      return blocks().getBlocks()
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 20;
}

.back-filter {
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: grayscale(100%);
  cursor: pointer;
  transition: width 0.5s;
  transition-timing-function: ease;
}

.content-menu {
  max-width: 0;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  background-color: white;
  transition: max-width 0.5s;
  transition-timing-function: ease;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}

.menu {
  max-width: 500px;
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 5px;
  padding: 200px 15px 100px;
  margin: 0 auto;
  align-items: center;
  transition: opacity 0.2s;
}

.link {
  text-decoration: none;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  column-gap: 22px;
  font-size: 1.1rem;
  padding: 7px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.link:hover,
.router-link-active {
  background-color: var(--link-color);
}

.close {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}

.content-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
}

.content-icon {
  padding: 12px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
}

.icon {
  width: 20px;
  height: 20px;
}

@media all and (max-width: 600px) {
  .menu {
    padding-top: 150px;
  }
}
</style>
