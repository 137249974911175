var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header"},[_c('div',{staticClass:"header-part"},[_c('div',{staticClass:"content-filter"},[_c('img',{attrs:{"src":require('../../assets/team-work.jpg'),"alt":"illustration"}}),_c('div',{staticClass:"filter"})]),_c('div',{staticClass:"content-title"},[_c('h1',{on:{"click":function($event){return _vm.redirect('/')}}},[_vm._v("ArteBeauté "),_c('span',[_vm._v("App")])]),_c('div',{staticClass:"content-icons"},_vm._l((_vm.$vuetify.breakpoint.width < 600
            ? _vm.icons.filter(i => !!i.responsive)
            : _vm.icons),function(icon,index){return _c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:'icon-' + index,staticClass:"content-icon",on:{"click":function($event){return _vm.iconClick(icon.action, icon.to)}}},[_c('img',{staticClass:"icon",attrs:{"src":icon.img,"title":icon.title,"alt":icon.title}})])}),0)]),_c('div',{staticClass:"content-spacer",style:({ height: _vm.displayLittleHeader ? _vm.heightSpacer + 'px' : 0 })}),(_vm.displayNav)?_c('div',{staticClass:"nav-header"},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"#6476D4","slider-size":"3","optional":""},model:{value:(_vm.pageNav),callback:function ($$v) {_vm.pageNav=$$v},expression:"pageNav"}},[_vm._l((_vm.$store.state.blocks.blocks),function(category,index){return [_c('v-tab',{key:'nav-' + index,staticClass:"item-nav",on:{"click":function($event){return _vm.redirect(category.items[0].to)}}},[_c('span',[_vm._v(_vm._s(category.title))])]),_vm._l((_vm.pageNav === index
              ? category.items
              : []),function(ssCategory,ss_index){return _c('v-tab',{key:'ssNav-' + index + '-' + ss_index,staticClass:"secondary-item-nav",class:{
              'last-secondary-item-nav':
                ss_index === category.items.length - 1,
              'exact-select-nav': _vm.exactPage === index + '-' + ss_index
            },on:{"click":function($event){return _vm.redirect(ssCategory.to)}}},[_c('span',[_vm._v(_vm._s(ssCategory.title))])])})]})],2)],1):_vm._e()]),_c('header-app-menu',{attrs:{"display":_vm.displayMenu},on:{"close":function($event){_vm.displayMenu = false},"icon-click":_vm.iconClick}}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('v-avatar',{staticClass:"mt-3",attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.firstChar))])]),_c('h3',{staticClass:"mt-3"},[(_vm.isLoggin.firstname)?_c('span',[_vm._v(" "+_vm._s(_vm.isLoggin.firstname)+" "+_vm._s(_vm.isLoggin.lastname)+" ")]):_vm._e()]),_c('p',{staticClass:"caption mt-1"},[_vm._v(_vm._s(_vm.isLoggin.email))]),_c('v-btn',{staticClass:"mb-4",attrs:{"text":"","outlined":"","rounded":""},on:{"click":function($event){return _vm.getAction(1)}},model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}},[_vm._v(" Gérer votre compte ")]),_c('v-divider'),_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"text":""},on:{"click":function($event){return _vm.getAction(2)}}},[_c('v-icon',[_vm._v("mdi-account")]),_c('span',{staticClass:"ml-2"},[_vm._v("Gérer les comptes")])],1),_c('v-divider'),_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","outlined":""},on:{"click":function($event){return _vm.getAction(3)}}},[_vm._v(" Déconnexion ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }