<template>
  <div class="content-header">
    <div class="header-part">
      <div class="content-filter">
        <img :src="require('../../assets/team-work.jpg')" alt="illustration" />
        <div class="filter" />
      </div>
      <div class="content-title">
        <h1 @click="redirect('/')">ArteBeauté <span>App</span></h1>
        <div class="content-icons">
          <div
            v-for="(icon, index) in $vuetify.breakpoint.width < 600
              ? icons.filter(i => !!i.responsive)
              : icons"
            :key="'icon-' + index"
            class="content-icon"
            v-ripple
            @click="iconClick(icon.action, icon.to)"
          >
            <img
              class="icon"
              :src="icon.img"
              :title="icon.title"
              :alt="icon.title"
            />
          </div>
        </div>
      </div>
      <div
        class="content-spacer"
        :style="{ height: displayLittleHeader ? heightSpacer + 'px' : 0 }"
      />
      <div v-if="displayNav" class="nav-header">
        <v-tabs
          v-model="pageNav"
          background-color="transparent"
          color="#6476D4"
          slider-size="3"
          optional
        >
          <template v-for="(category, index) in $store.state.blocks.blocks">
            <v-tab
              :key="'nav-' + index"
              class="item-nav"
              @click="redirect(category.items[0].to)"
            >
              <span>{{ category.title }}</span>
            </v-tab>
            <v-tab
              v-for="(ssCategory, ss_index) in pageNav === index
                ? category.items
                : []"
              :key="'ssNav-' + index + '-' + ss_index"
              class="secondary-item-nav"
              :class="{
                'last-secondary-item-nav':
                  ss_index === category.items.length - 1,
                'exact-select-nav': exactPage === index + '-' + ss_index
              }"
              @click="redirect(ssCategory.to)"
            >
              <span>{{ ssCategory.title }}</span>
            </v-tab>
          </template>
        </v-tabs>
      </div>
    </div>
    <header-app-menu
      :display="displayMenu"
      @close="displayMenu = false"
      @icon-click="iconClick"
    />
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar class="mt-3" color="primary">
              <span class="white--text headline">{{ firstChar }}</span>
            </v-avatar>

            <h3 class="mt-3">
              <span v-if="isLoggin.firstname">
                {{ isLoggin.firstname }} {{ isLoggin.lastname }}
              </span>
            </h3>
            <p class="caption mt-1">{{ isLoggin.email }}</p>

            <v-btn
              v-model="action"
              @click="getAction(1)"
              class="mb-4"
              text
              outlined
              rounded
            >
              Gérer votre compte
            </v-btn>
            <v-divider></v-divider>
            <v-btn @click="getAction(2)" text class="mt-2 mb-2">
              <v-icon>mdi-account</v-icon>
              <span class="ml-2">Gérer les comptes</span>
            </v-btn>
            <v-divider></v-divider>
            <v-btn @click="getAction(3)" class="mt-2" text outlined>
              Déconnexion
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import iconsHead from '@/helpers/iconsHeader'
import navHead from '@/helpers/navHeader'
import HeaderAppMenu from './header-app-menu'

export default {
  name: 'header-app',
  components: { HeaderAppMenu },
  methods: {
    iconClick(key = 'redirection', to) {
      switch (key) {
        case 'redirection':
          this.redirect(to)
          break
        case 'auth':
          this.dialog = true
          break
        case 'menu':
          this.displayMenu = true
          break
      }
    },
    getAction(val) {
      if (val === 2) {
        this.dialog = false
      }
      this.$emit('authAction', val)
    },
    redirect(to) {
      if (this.$route.path !== to) this.$router.push(to)
    },
    scrolled() {
      const currentScroll =
        document.body.scrollTop || document.documentElement.scrollTop
      this.heightSpacer = currentScroll <= 100 ? 100 - currentScroll : 0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrolled, false)
    this.scrolled()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolled, false)
  },
  watch: {
    $route() {
      if (this.displayNav) {
        let pageExact = ''
        const pageCat = this.$store.state.blocks.blocks.findIndex(
          ({ items }) => {
            pageExact = items.findIndex(({ to }) => {
              return to === this.$route.path
            })
            return pageExact !== -1
          }
        )
        this.exactPage = pageCat + '-' + pageExact
        this.pageNav = pageCat
      }
    }
  },
  computed: {
    isLoggin() {
      return this.$store.state.auth.user
    },
    firstChar() {
      return this.$store.state.auth.user.firstname
        ? this.$store.state.auth.user.firstname.charAt(0)
        : '?'
    },
    displayLittleHeader() {
      return this.littleHeader.findIndex(nav => this.$route.path === nav) === -1
    },
    displayNav() {
      return (
        this.navHead.findIndex(nav => this.$route.path.startsWith(nav)) !== -1
      )
    }
  },
  data() {
    return {
      icons: iconsHead().getIcons(),
      navHead: navHead().getPath(),
      littleHeader: navHead().getLittleHeader(),
      heightSpacer: 100,
      dialog: false,
      action: null,
      pageNav: null,
      exactPage: null,
      displayMenu: false
    }
  }
}
</script>

<style scoped>
.content-header {
  width: 100%;
  height: 230px;
}

.header-part {
  width: 100%;
  background-color: #e3e6ee;
  padding: 30px 40px 0;
  position: fixed;
  z-index: 10;
}

h1 {
  color: black;
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
}

h1 span {
  font-weight: normal;
}

.content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.content-icons {
  display: flex;
  align-items: center;
}

.content-icon {
  padding: 12px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
}

.icon {
  width: 20px;
  height: 20px;
}

.content-filter {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-filter img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.filter {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(170deg, #accdff, #d1acff);
  opacity: 0.7;
}

.content-spacer {
  width: 100%;
  padding-bottom: 30px;
}

.nav-header {
  position: relative;
}

.v-tab--active {
  background-color: rgba(255, 255, 255, 0.5);
}

.v-tab--active.item-nav {
  border-top-left-radius: 10px;
}

.v-tab.v-tab--active span {
  color: #6476d4;
}

.v-tab--active.item-nav span {
  font-weight: 900;
}

.v-tab {
  transition: background-color 0s;
}

.v-tab span {
  font-weight: 700;
  color: black;
  letter-spacing: normal;
}

.secondary-item-nav {
  background-color: rgba(255, 255, 255, 0.5);
}

.exact-select-nav {
  background-color: white;
}

.last-secondary-item-nav {
  border-top-right-radius: 10px;
}

@media all and (max-width: 600px) {
  .header-part {
    padding: 10px 15px 0;
  }

  .content-spacer {
    padding-bottom: 10px;
  }
}
</style>
