<template>
  <div>
    <v-dialog width="50vw" v-model="editAuth">
      <userForm v-model="editAuth" :currentAuth="$store.state.auth.user" />
    </v-dialog>
    <v-dialog max-width="80vw" v-model="listAuth">
      <userList v-model="listAuth" />
    </v-dialog>
    <header-app @authAction="getActions" />
    <v-main style="min-height: 100vh" class="my-14 mx-auto px-5">
      <transition name="page">
        <slot v-if="!$store.getters['pageLoading/isLoad']" />
        <v-skeleton-loader
          v-else
          type="list-item-avatar-three-line, image, article"
        />
      </transition>
    </v-main>
    <footer>Biremont@Multimedia</footer>
  </div>
</template>

<script>
import swal from 'sweetalert'
import userForm from '../components/users/form'
import userList from '../components/users/list'
import HeaderApp from '../components/general/header-app'
export default {
  components: { HeaderApp, userForm, userList },
  data: () => {
    return {
      editAuth: false,
      listAuth: false,
    }
  },
  methods: {
    async getActions(val) {
      if (val === 1) {
        this.editAuth = true
      } else if (val === 2) {
        this.listAuth = true
      } else if (val === 3) {
        // this.$router.push("/login");
        const logOut = await swal({
          buttons: {
            cancel: this.$t('layout.cancel'),
            disconnect: this.$t('layout.signOut'),
          },
          title: this.$t('layout.login'),
          text: this.$t('layout.areYouSure'),

          dangerMode: true,
        })

        if (logOut === 'disconnect') {
          this.$router.push('/login')
        }
      }
    },
  },
}
</script>

<style scoped>
footer {
  background-color: rgb(30, 30, 30);
  color: white;
  width: 100%;
  padding: 20px;
}

/*
 * page
 */

.page-enter-active {
  animation: page-in 0.4s;
}
.page-leave-active {
  animation: page-out 0.2s;
}

@keyframes page-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes page-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
